import * as React from "react"
import { Link } from "gatsby"

export const ArticlePreview: React.SFC<ArticlePreviewProps> = ({ category, title, url, blurb }) => (
  <>
    {categoryHeader(category)}
    <Link className="article-link" to={url}>
      <article className="article-preview flex flex-row">
        <div className="w-16">
          <h3 className="text-2xl leading-loose lg:leading-relaxed font-mono italic text-black font-bold">{statusNumber(title)}</h3>
        </div>
        <div className="flex-1">
          <div className="text-gray-800">{statusNumberTitle(title)}</div>
          <div className="text-gray-600 text-sm" dangerouslySetInnerHTML={{ __html: blurb }} />
        </div>
      </article>
    </Link>
  </>
)

const statusNumberTitle = (title: string) => title.split(" - ")[1]

const statusNumber = (title: string) => title.split(" - ")[0]

const categoryHeader = (category?: string) => {
  if (category) {
    return (
      <h2 className="text-2xl pt-8 mt-8 uppercase text-gray-600 border-t border-gray-200">{category}</h2>
    )
  }
}

export default ArticlePreview

export interface ArticlePreviewProps {
  title: string
  url: string
  blurb: string
  date: string
  category: string
}
