import * as React from "react"
import { ArticlePreview, ArticlePreviewProps } from "../ArticlePreview"

export const ArticleList: React.SFC<ArticleListProps> = ({ articles }) => (
  <div id="articles" className="">
    {articles.map((props, i) => {

      if (lastCategory !== props.category) {
        lastCategory = props.category
        return (
          <ArticlePreview key={i} {...props} />
        )
      } else {
        props.category = ""
        return (
          <ArticlePreview key={i} {...props} />
        )
      }

    })}
  </div>
)

export default ArticleList

let lastCategory = ""

export interface ArticleListProps {
  articles: ArticlePreviewProps[]
}
