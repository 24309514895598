import * as React from "react"
import Link from "gatsby-link"
import Helmet from "react-helmet"
import Header from "../components/Header"
import ArticleList from "../components/ArticleList"
import SideBar from "../components/SideBar"
import * as config from "../constants"
import { graphql } from 'gatsby'
import { TemplateWrapper } from "../layouts"
import MainContent from "../components/MainContent"

export const IndexPage = (props) => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  const now = new Date();

  const list = posts
    .map(({ node: post }) => ({
      url: `/${post.frontmatter.code}`,
      category: post.frontmatter.category,
      title: `${post.frontmatter.code} - ${post.frontmatter.name}`,
      blurb: post.frontmatter.description,
    }))
  //TODO: Change coverImage to 1200x1200 that crops nicely to 1200x630 on FB
  const coverImage = `${config.DOMAIN}/img/twitter-default.png`
  return (
    <TemplateWrapper>
      <MainContent>
        <section className="mb-6">
          <h1>HTTP Response Status Codes</h1>
          <p className="mt-3 text-gray-600 leading-relaxed">
            An easy to navigate &amp; understand listing of official &amp; unofficial status codes you may receive while visiting a web site, using an API or working with a type of service.
          </p>
        </section>
        <ArticleList articles={list} />
        <Helmet
          title={`${config.SITE_TITLE}`}
          meta={[
            { name: "description", content: config.SITE_DESCRIPTION },
            { property: "og:site_name", content: config.SITE_TITLE },
            { property: "og:type", content: "website" },
            { property: "og:title", content: config.SITE_TITLE },
            { property: "og:description", content: config.SITE_DESCRIPTION },
            { name: "ahrefs-site-verification", content: "5db91549d22dedf0ecc2e44125339010d6741c2af73bc7e7cd17bf01523775fc" },
            // { property: "og:url", content: config.DOMAIN },
            // { property: "og:image", content: coverImage },
            // { name: "twitter:card", content: "summary_large_image" },
            // { name: "twitter:title", content: config.SITE_TITLE },
            // { name: "twitter:description", content: config.SITE_DESCRIPTION },
            // { name: "twitter:url", content: config.DOMAIN },
            // { name: "twitter:site", content: "@" + config.TWITTER },
            // { name: "twitter:image", content: `${config.DOMAIN}/img/twitter-default.png` },
          ]}
        >
          {/* <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Person",
              "name": config.SITE_TITLE,
              "url": config.DOMAIN,
              "logo": `${config.DOMAIN}/img/profile-1.gif`,
            })}
          </script> */}
        </Helmet>
      </MainContent>
    </TemplateWrapper>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
  allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___code]}, filter: {frontmatter: {templateKey: {eq: "statuscodes"}}}) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            code
            name
            category
            description
          }
        }
      }
    }
  }
`
